import React, { Fragment } from 'react'
import Facts from '../../components/Facts'
import Team from '../../components/Team'
import { Four, One, Row, RowGroup, Three, Two } from '../../components/Grid'
import OtherProjects from '../../components/OtherProjects'
import Image from '../../components/Image'
import ProjectsContext from '../../components/ProjectsContext'
import Caption from '../../components/Caption'
import SEO from '../../components/SEO'
import { ProjectName } from '../../components/Style'
import Link from '../../components/Link'
import ContactForm from '../../components/ContactForm'
import Layout from '../../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/projects",
      "name": "Projects"
    }
  },{
    "@type": "ListItem",
    "position": 2,
    "item": {
      "@id": "https://500tech.com/projects/bitsofgold",
      "name": "Bits of Gold"
    }
  }]
}`

export default () => {
  return (
    <Layout>
      <SEO
        path="/projects/bitsofgold"
        title="Featured project for Bits of Gold by 500Tech"
        description="We’ve built Israel’s largest Bitcoin and Ethereum exchange for Bits of Gold."
        ld={breadcrumbs}
      />

      <ProjectsContext.Consumer clientName="bitsofgold">
        {({ allProjects, currentProject }) => (
          <Fragment>
            <ProjectName>{currentProject.projectName}</ProjectName>
            <Facts {...currentProject} />

            <Row>
              <Three>
                <p>
                  Bits of Gold is Israel’s largest Bitcoin and Ethereum
                  exchange.
                </p>

                <p>
                  We’ve built Bits of Gold’s first website, management console,
                  and blockchain integration to allow a rapid launch during
                  Bitcoin’s rapid growth in 2013. Over the next year we helped
                  transition the code to an internal team and provided
                  continuous support and feature additions.
                </p>

                <p>
                  <Link to="http://bitsofgold.co.il/en/" white>
                    www.bitsofgold.co.il
                  </Link>
                </p>
              </Three>
            </Row>

            <Row>
              <Four>
                <Image path="projects/bitsofgold/bitsofgold-2.jpg" />
              </Four>
            </Row>

            <Team team={currentProject.team} />

            <RowGroup>
              <Row>
                <One>
                  The project included three key parts: user portal, admin back
                  office, and monitoring tools.
                </One>
                <Three>
                  <Image
                    innerShadow
                    path="projects/bitsofgold/bitsofgold-payment-methods.jpg"
                  />
                </Three>
              </Row>

              <Row>
                <Two>
                  <Image
                    innerShadow
                    path="projects/bitsofgold/bitsofgold-currency.jpg"
                  />
                  <Caption>
                    We implementd non-public services to generate wallets,
                    monitor Bitcoin transactions, and securely make buy and sell
                    transactions.
                  </Caption>
                </Two>
                <Two>
                  <Image
                    innerShadow
                    path="projects/bitsofgold/bitsofgold-orders.jpg"
                  />
                  <Caption>
                    The admin portal was made for Bits of Gold’s operators to
                    monitor and support complex purchase and sell flows.
                  </Caption>
                </Two>
              </Row>
            </RowGroup>

            <Row>
              <One>
                <Image path="projects/bitsofgold/bitsofgold-coins.png" />
              </One>
            </Row>

            <OtherProjects currentProject={currentProject} />
          </Fragment>
        )}
      </ProjectsContext.Consumer>
      <ContactForm />
    </Layout>
  )
}
